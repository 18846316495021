import { AlertVariantType } from '@ldfeplatform/componentlibrary.types';
import { GOOGLE_PLACES_API_REQUEST_STATUS, SearchAlertType, E_ActionType } from './constants';
import { IError, IQueryItem, IFetchTypeAheadPlaces, IGetStoreLocations } from './store-locator.interface';
import { getStoreLocatorSearchResults } from '@/api/store-locator';
import { FILTER_ORDER } from '../store-locator-filter/constants';

const { OK, INVALID_REQUEST, ZERO_RESULTS, UNKNOWN_ERROR, ERROR, NOT_FOUND } = GOOGLE_PLACES_API_REQUEST_STATUS;

export const fetchTypeaheadPlaces = async ({ setSuggestedPlaces, value, setSearchAlert }: IFetchTypeAheadPlaces) => {
  if (!value) {
    setSuggestedPlaces([]);
    setSearchAlert(SearchAlertType.Empty);
    return;
  }
  const autocompleteService = new google.maps.places.AutocompleteService();

  let options = {
    input: value.toString(),
    componentRestrictions: { country: 'ca' },
    types: ['geocode'],
  };

  try {
    await autocompleteService.getPlacePredictions(options, (results, status) => {
      if (status === (ZERO_RESULTS || NOT_FOUND || INVALID_REQUEST)) {
        setSearchAlert(SearchAlertType.Invalid);
      } else if (status === (ERROR || UNKNOWN_ERROR)) {
        setSearchAlert(SearchAlertType.Error);
      } else if (status === OK) {
        const predictions: IQueryItem[] =
          results?.map((place) => {
            const { description } = place;
            return { displayText: description, plainText: description };
          }) ?? [];
        setSuggestedPlaces(predictions);
        setSearchAlert(SearchAlertType.None);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryIds = () => {
  // Get current URL params
  const urlParams = new URLSearchParams(window.location.search);
  const paramsObj: Record<string, string> = {};
  urlParams.forEach((value, key) => {
    paramsObj[key] = value;
  });

  // Extract category IDs, converting kebab-case to camelCase for comparison
  const categoryIds = Object.entries(paramsObj)
    .filter(([key]) => {
      // Convert kebab-case to camelCase (e.g., 'store-type' -> 'storeType')
      const camelKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      return FILTER_ORDER.includes(camelKey);
    })
    .flatMap(([_, value]) => value.split(',').map((id) => id.trim()));
  return categoryIds;
};

export const getStoreLocations = async ({ longitude, latitude, dispatch, setSearchAlert }: IGetStoreLocations) => {
  try {
    const categoryIds = getCategoryIds();
    const coordinates = { latitude, longitude } || getCoordinatesFromUrl();
    const data = await getStoreLocatorSearchResults({
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      categoryId: categoryIds,
    });
    dispatch({ type: E_ActionType.update, payload: data });
    if (!data) {
      setSearchAlert(SearchAlertType.NoStores);
    }
  } catch (error) {
    dispatch({ type: E_ActionType.error, payload: { error: error as IError } });
  }
};

export const getGeoCodes = async ({
  selectedAddress,
  setSelectedCoordinates,
  setSearchAlert,
  selectedCoordinates,
  dispatch,
}: any) => {
  await google.maps.importLibrary('geocoding');

  const geocoderService = new google.maps.Geocoder();
  const geocoderRequestObject = {
    address: selectedAddress,
  };

  if (!selectedAddress) {
    setSearchAlert(SearchAlertType.Empty);
    return;
  }

  try {
    await geocoderService.geocode(geocoderRequestObject, (results, status) => {
      if (status === OK) {
        const newLongitude = results?.[0].geometry?.location.lng();
        const newLatitude = results?.[0].geometry?.location.lat();

        const { longitude, latitude } = selectedCoordinates;

        if (newLongitude && newLatitude) {
          if (longitude !== newLongitude && latitude !== newLatitude) {
            setSelectedCoordinates({ longitude: newLongitude, latitude: newLatitude });
            getStoreLocations({
              longitude: String(newLongitude),
              latitude: String(newLatitude),
              dispatch,
              setSearchAlert,
            });
          }
        }

        setSearchAlert(SearchAlertType.None);
      } else if (status === INVALID_REQUEST || ZERO_RESULTS) {
        setSearchAlert(SearchAlertType.Invalid);
      } else if (status === UNKNOWN_ERROR || ERROR) {
        setSearchAlert(SearchAlertType.Error);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const getSearchAlertProps = (searchAlert: string, formatMessage: any) => {
  let contentTitle: string = '';
  let variantId: AlertVariantType = 'WARNING';
  let id: string = '';

  switch (searchAlert) {
    case SearchAlertType.Empty:
      id = 'emptyFieldWarning';
      break;
    case SearchAlertType.NonExistentStore:
      id = 'storeDoesntExist';
      break;
    case SearchAlertType.Invalid:
      id = 'storeLocatorInvalidAddress';
      break;
    case SearchAlertType.NoStores:
      id = 'storeLocatorNoStoresReturned';
      break;
    case SearchAlertType.Error:
      id = 'storeLocatorError';
      variantId = 'ERROR';
  }

  let alertProps = {
    contentTitle,
    variantId,
    contentBody1: formatMessage({
      id: id,
    }),
    hasCloseButton: true,
    materialIcon: '',
  };
  return alertProps;
};

export const handleGeoLocationError = (geoLocationError: any) => {
  if (geoLocationError?.code) {
    console.warn(geoLocationError?.code);
  }
};

export const geoLocationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
};

export const getCoordinatesFromUrl = () => {
  // Get current URL params
  const urlParams = new URLSearchParams(window.location.search);
  const latitude = urlParams.get('latitude');
  const longitude = urlParams.get('longitude');

  if (latitude && longitude) {
    return {
      latitude,
      longitude,
    };
  }

  return null;
};

export const validateStoreNumberSearch = (value: string) => {
  // Validate if search was in the correct format
  const validationRegex = /^(store\s*#?\d{1,4}|magasin\s*#?\d{1,4}|#\d{1,4})$/;
  return validationRegex.test(value.toLowerCase());
};
