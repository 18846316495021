import React, { FC } from 'react';

import { Icon } from '@ldfeplatform/componentlibrary.ui.atoms.icon';
import { Button } from '@ldfeplatform/componentlibrary.ui.atoms.button';
import { Text } from '@ldfeplatform/componentlibrary.ui.atoms.text';
import { useTheme } from '@chakra-ui/react';

import { IIconButton } from './action-button.interface';

const ActionButton: FC<IIconButton> = ({
  linkText,
  linkUrl = '',
  icon,
  iconWidth,
  iconHeight,
  iconFill,
  rightIcon = false,
  fontWeight,
  handleClick,
  textDecoration,
  variant,
  openNewTab = false,
}): React.ReactElement => {
  const {
    palette: { black, white },
  } = useTheme();
  const iconButtonstyles = {
    bg: white,
    color: iconFill || black,
    fontWeight: fontWeight || 300,
    padding: '0',
    iconMargin: '0.334rem',
  };

  const buttonProps = variant
    ? { variant }
    : {
        bg: iconButtonstyles.bg,
        sx: { padding: iconButtonstyles.padding },
        _hover: { bg: iconButtonstyles.bg },
        _focus: { bg: iconButtonstyles.bg },
        _active: { bg: iconButtonstyles.bg },
      };

  const handleDefaultClick = () => {
    if (handleClick) {
      handleClick();
      return;
    } else if (linkUrl) {
      if (openNewTab) {
        window.open(linkUrl, '_blank');
      } else {
        window.location.href = linkUrl;
      }
    }
  };

  const buttonText = (
    <Text
      sx={{ fontWeight: iconButtonstyles.fontWeight }}
      textDecoration={textDecoration}
      color={iconButtonstyles.color}
    >
      {linkText}
    </Text>
  );

  return (
    <Button {...buttonProps} onClick={handleDefaultClick}>
      {rightIcon ? buttonText : null}
      <Icon
        as={icon}
        color={iconButtonstyles.color}
        w={iconWidth}
        h={iconHeight}
        mr={!rightIcon ? iconButtonstyles.iconMargin : '0rem'}
        ml={rightIcon ? iconButtonstyles.iconMargin : '0rem'}
        role="img"
        aria-hidden="true"
      />
      {!rightIcon ? buttonText : null}
    </Button>
  );
};

export default ActionButton;
