import React, { useEffect, useCallback } from 'react';

import { usePathname, useSearchParams } from 'next/navigation';

import { Tabs } from '@ldfeplatform/componentlibrary.ui.molecules.tabs';

import { ITabComponent } from './tab.interface';

import { updateSearchParams } from '@/utils';

const TabComponent: React.FC<ITabComponent> = (props) => {
  const { activeIndex, setActiveIndex, navigationItems, setNavigationItems, isUpdateStateRequired } = props;
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    setNavigationItems((prevState: any) =>
      prevState.map((item: any, i: any) => {
        item.isActive = i === activeIndex;
        item.icon.url = item.isActive ? item.iconUrls?.activated : item.iconUrls?.default;
        return item;
      })
    );
  }, [activeIndex]);

  const showTabContenet = useCallback(
    (redirectRoute: string) => {
      navigationItems.forEach((item: any, i: any) => {
        if (redirectRoute === item.url) {
          setActiveIndex(i);
          isUpdateStateRequired &&
            window.history.pushState(
              null,
              '',
              `${pathname}${updateSearchParams(searchParams.toString(), 'tab', item.url.substring(1))}`
            );
        }
      });
    },
    [setActiveIndex]
  );
  return (
    <Tabs
      navigationItems={navigationItems}
      tabClickHandler={showTabContenet}
      tabsAriaLabel="tabs-component"
      trackingFn={() => console.log}
    />
  );
};

export default TabComponent;
