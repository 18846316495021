import React from 'react';
import { useIntl } from 'react-intl';

import { useTheme, Grid, GridItem } from '@chakra-ui/react';
import Accordion from '@/components/accordion';
import { Text } from '@ldfeplatform/componentlibrary.ui.atoms.text';

import { useGetStoreHoursData } from '@/hooks';
import { DAYS_OF_WEEK_MAP } from '@/data/constants';

import { IStoreHoursSection } from './store-hours-section.interface';
import { TStoreHours } from '@/components/store-locator/store-locator.interface';
import styles from './store-hours-section.module.scss';

const StoreHoursSection: React.FC<IStoreHoursSection> = ({ searchResults, isMapView, postOfficeLink }) => {
  const { formatMessage } = useIntl();
  const {
    common: { common03Regular, common03Bold, common04Regular },
    sp02,
    sp03,
    sp05,
    palette: { sdmRed },
    text: { deemphasized },
  } = useTheme();

  const currentDate = new Date();
  const dayKey = DAYS_OF_WEEK_MAP[currentDate.getDay()];
  const currentDayOfWeek = formatMessage({ id: dayKey });
  const { storeHoursLabel, isStoreClosed } = useGetStoreHoursData(
    searchResults?.storeHours,
    currentDate,
    currentDayOfWeek,
    searchResults?.closed
  );

  const getAccordionLabel = () => {
    return (
      <div className={styles.accordionHeading}>
        <Text sx={{ ...common03Bold, color: isStoreClosed ? sdmRed : '#03853d' }} as="h3">
          {isStoreClosed ? formatMessage({ id: 'closed' }) : formatMessage({ id: 'open' })}
        </Text>
        <Text sx={{ ...common03Bold, pl: sp02, pr: isMapView && sp03 }} as="h3">
          {storeHoursLabel()}
        </Text>
      </div>
    );
  };

  const getAccordionBody = () => {
    const storeHours = searchResults?.storeHours.map(
      ({ timeRange, nameOfDay, isHoliday = false, holidayName = '' }: TStoreHours, index: number) => (
        <Grid
          key={index}
          className={styles.storeHoursGrid}
          sx={{ ...(nameOfDay === currentDayOfWeek ? common03Bold : common03Regular) }}
          mb={sp02}
        >
          <GridItem sx={{ textAlign: 'left' }}>
            <Text>{nameOfDay}:</Text>
            {isHoliday ? <Text sx={{ ...common04Regular, color: deemphasized }}>{`(${holidayName})`}</Text> : null}
          </GridItem>
          <GridItem colStart={2} sx={{ textAlign: 'right' }}>
            <Text>{timeRange}</Text>
            {isHoliday ? (
              <Text sx={{ ...common04Regular, color: deemphasized }}>{formatMessage({ id: 'holidayHours' })}</Text>
            ) : null}
          </GridItem>
        </Grid>
      )
    );

    return (
      <div className={styles.accordionBody}>
        {storeHours}
        <Text sx={{ ...common03Regular }} as="h3" mt="1.333rem">
          {formatMessage(
            { id: 'postOfficeHoursVary' },
            {
              a: (chunks) => (
                <a href={postOfficeLink} style={{ textDecoration: 'underline' }} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
            }
          )}
        </Text>
      </div>
    );
  };

  return (
    <div className={styles.cardStoreTimingContainer} style={{ marginBottom: isMapView ? sp02 : sp05 }}>
      {isMapView ? <>{getAccordionLabel()}</> : <Accordion heading={getAccordionLabel()} body={getAccordionBody()} />}
    </div>
  );
};

export default StoreHoursSection;
