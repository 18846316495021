import { cookies, LOCALE } from '@/data/constants';
import { getSiteId, isPharmaprixBanner } from '@/utils';
import * as Sentry from '@sentry/react';
import client from 'Api/datasources/bff-datasources';
import UniversalCookies from 'universal-cookie';

const Cookies = new UniversalCookies();
const defaultLocale = isPharmaprixBanner() ? LOCALE.FR : LOCALE.EN;

const STORE_LOCATOR_BASE_PATH = '/store-locator';

const buildBaseUrlOnServerSide = (req: any) => `${process.env.NEXT_PUBLIC_BFF_URL}/${getSiteId(req)}`;

export const getStoreLocatorSearchResults = async (body: any) => {
  const reqBody = {
    radius: 5,
    ...body,
  };

  const params = {
    lang: Cookies.get(cookies.USER_PREFERRED_LOCALE) || defaultLocale,
  };

  try {
    const response = await client.post(`${STORE_LOCATOR_BASE_PATH}/search`, reqBody, { params });
    return response?.data;
  } catch (error: any) {
    Sentry.captureMessage('Failed to load store locator data. ' + error.response);
    console.error('Failed to fetch store locator data:', error.response);
    return { error: error.response.status };
  }
};

export const getStoreDetails = async (storeId: string, lang: string, req?: any) => {
  const reqBody = {
    storeId: storeId,
  };
  const params = {
    lang: lang || defaultLocale,
  };

  const baseURL = buildBaseUrlOnServerSide(req);

  const response = await client.post(`${STORE_LOCATOR_BASE_PATH}/single-store-details`, reqBody, {
    baseURL,
    params,
  });

  return { response: response?.data, b3ID: response?.config?.headers?.b3 || null };
};

export const getStoreLocatorPharmacistOwners = async (req: any, lang: string) => {
  const params = {
    lang: lang || defaultLocale,
  };

  const baseURL = buildBaseUrlOnServerSide(req);

  const response = await client.get(`${STORE_LOCATOR_BASE_PATH}/pharmacy-list`, { baseURL, params });
  return response?.data;
};
