'use client';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMap, useMapsLibrary, Map, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';
import { Text, useTheme } from '@chakra-ui/react';
import { useViewportType } from '@/hooks';
import { useDebouncedCallback } from 'use-debounce';

import {
  DEFAULT_CANADA_LAT_LNG,
  DEFAULT_GESTURE_HANDLING,
  DEFAULT_MAP_ZOOM,
  DEFAULT_MARKER_ZOOM,
  GEOCODING_LIB,
  ID,
  MARKER_SCALE,
  SELECTED_MARKER_SCALE,
} from './map-constants';
import { IMapProps, IPosition } from './map.interface';

import { MapCard } from '../map-card';

import styles from './map.module.scss';
import { TSearchResults } from '../store-locator/store-locator.interface';

const calculateRadius = (zoomLevel: number, map: google.maps.Map | null, center: any) => {
  const EARTH_RADIUS_KM = 6371;
  const zoom = Math.pow(2, zoomLevel);
  const distance = (2 * Math.PI * EARTH_RADIUS_KM) / (zoom * 256);
  const kmPerPixel = distance * Math.cos(center.lat() * (Math.PI / 180));
  const mapWidth = map?.getDiv().offsetWidth || 256;
  const radius = kmPerPixel * (mapWidth / 2);

  return radius;
};

const MapComponent: FC<IMapProps> = ({
  initialPosition,
  positions,
  storeLocatorResults = [],
  hasResults,
  onPinSelected,
  contentRef,
  handleMapInteraction,
  boundsUpdate,
  setBoundsUpdate,
}): React.ReactElement | null => {
  const DEBOUNCE_INTERVAL = 300;
  const {
    palette: { sdmRed, white },
  } = useTheme();
  const [selectedPosition, setSelectedPosition] = useState<google.maps.LatLngLiteral>();
  const viewportType = useViewportType();
  const map = useMap(ID);
  const geocodingLib = useMapsLibrary(GEOCODING_LIB);
  const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);

  useEffect(() => {
    if (!geocoder || !map) return;
    const bounds = new google.maps.LatLngBounds();
    if (positions?.length && boundsUpdate) {
      positions.forEach(({ latitude, longitude }) => {
        bounds.extend({ lat: latitude, lng: longitude });
      });

      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01
        );
        const extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      map.fitBounds(bounds);
      map.setCenter(bounds.getCenter());
    }
  }, [geocoder, map, initialPosition, positions]);

  const handlePinCentering = (position: google.maps.LatLngLiteral): void => {
    setBoundsUpdate(true);
    if (map) {
      setSelectedPosition(position);
      map.panTo(position);
      map.setZoom(DEFAULT_MARKER_ZOOM);
    }
  };

  const isPinSelected = (lat: number, lng: number) => selectedPosition?.lat === lat && selectedPosition?.lng === lng;

  const renderMarkers = (markers?: IPosition[]) => {
    return (
      markers &&
      markers.map(({ position, latitude, longitude }, index) => {
        const isNotSelected = !isPinSelected(latitude, longitude);
        return (
          <AdvancedMarker
            key={index}
            position={{ lat: latitude, lng: longitude }}
            onClick={() => {
              onPinSelected(index);
            }}
          >
            <Pin
              background={isNotSelected ? white : null}
              borderColor={isNotSelected ? sdmRed : null}
              scale={isNotSelected ? MARKER_SCALE : SELECTED_MARKER_SCALE}
            >
              {isNotSelected ? <Text className={styles.pinElement}>{position}</Text> : null}
            </Pin>
          </AdvancedMarker>
        );
      })
    );
  };

  const debouncedMapInteraction = useDebouncedCallback((center, radius) => {
    if (!boundsUpdate) {
      handleMapInteraction(center, radius);
    }
  }, DEBOUNCE_INTERVAL);

  const handleMapChange = () => {
    if (boundsUpdate) {
      setBoundsUpdate(false);
      return;
    }
    const center = map?.getCenter();
    const zoom = map?.getZoom();
    const radius = calculateRadius(zoom || DEFAULT_MAP_ZOOM, map, center);

    debouncedMapInteraction(center?.toJSON(), radius);
  };

  return (
    geocoder && (
      <div className={styles.mapContainer}>
        <Map
          id={ID}
          defaultZoom={DEFAULT_MAP_ZOOM}
          defaultCenter={DEFAULT_CANADA_LAT_LNG}
          onZoomChanged={handleMapChange}
          onCenterChanged={handleMapChange}
          mapId={process.env.NEXT_PUBLIC_GOOGLE_MAPS_MAP_ID}
          gestureHandling={DEFAULT_GESTURE_HANDLING}
          streetViewControl={viewportType === 'desktop'}
          zoomControlOptions={{
            position:
              viewportType === 'desktop'
                ? google.maps.ControlPosition.RIGHT_BOTTOM
                : google.maps.ControlPosition.RIGHT_TOP,
          }}
          mapTypeControl={false}
          fullscreenControl={false}
          keyboardShortcuts={false}
        >
          {renderMarkers(positions)}
        </Map>
        {viewportType !== 'desktop' && hasResults && (
          <div className={styles.cardsContainer}>
            {storeLocatorResults.map((results: TSearchResults, index: number) => (
              <MapCard
                index={index}
                searchResults={results}
                key={index}
                position={positions?.[index]}
                onCardClick={handlePinCentering}
                contentRef={contentRef}
              />
            ))}
          </div>
        )}
      </div>
    )
  );
};

export default MapComponent;
