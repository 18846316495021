import React from 'react';
import { useIntl } from 'react-intl';

import { useTheme, Center } from '@chakra-ui/react';
import { Divider } from '@ldfeplatform/componentlibrary.ui.atoms.divider';
import { Button } from '@ldfeplatform/componentlibrary.ui.atoms.button';
import { Text } from '@ldfeplatform/componentlibrary.ui.atoms.text';
import { GoTag } from 'react-icons/go';
import { FaRegCompass } from 'react-icons/fa';
import { MdOutlinePhone } from 'react-icons/md';
import PinsSvg from '@/components/pins-svg';
import IconButton from '@/components/action-button';
import StoreInfoHeader from './store-info-header/store-info-header.component';
import StoreHoursSection from './store-hours-section/store-hours-section.component';

import { isPharmaprixBanner, capitalizeEveryFirstLetter } from '@/utils';

import styles from './search-results-content.module.scss';
import { ISearchResultsContent } from './search-results-content.interface';

const SearchResultsContent: React.FC<ISearchResultsContent> = ({ index, searchResults, view, forwardRef }) => {
  const isDesktopView = view === 'desktop';
  const isMapView = view === 'mapView';
  const svgPinNumber = index + 1;
  const isPhx = isPharmaprixBanner();
  const { formatMessage } = useIntl();
  const {
    common: { common03Regular },
    sp03,
  } = useTheme();

  const handleClick = (url: string | undefined, openNewTab: boolean = false) => {
    if (url) {
      if (openNewTab) {
        window.open(url, '_blank');
      } else {
        window.location.href = url;
      }
    }
  };

  const renderStoreAddressSection = () => (
    <div className={styles.cardStoreAddressContainer}>
      <div className={styles.addressLine1}>
        <Text sx={{ ...common03Regular }} as="h3" pr={sp03}>
          {capitalizeEveryFirstLetter(searchResults?.address1)}
        </Text>
        <Center sx={{ alignSelf: 'flex-start' }} height="1.5rem" borderWidth="0.083rem">
          <Divider borderColor="#CACACA" orientation="vertical" />
        </Center>
        <Text sx={{ ...common03Regular, alignSelf: 'flex-start' }} pl={sp03} as="h3">
          {searchResults?.distance} km
        </Text>
      </div>
      <Text sx={{ ...common03Regular }} as="h3">
        {capitalizeEveryFirstLetter(searchResults?.city)}, {searchResults?.province}, {searchResults?.postalCode}
      </Text>
    </div>
  );

  const renderIconButtonSection = () =>
    !isMapView ? (
      <div className={styles.cardIconButtonsContainer}>
        {searchResults?.directionsCTA ? (
          <IconButton
            icon={FaRegCompass}
            linkUrl={searchResults?.directionsCTA}
            linkText={formatMessage({ id: 'getDirections' })}
            textDecoration="underline"
            openNewTab={true}
          />
        ) : null}
        {searchResults?.phoneNumber ? (
          <IconButton
            icon={MdOutlinePhone}
            linkUrl={`tel:${searchResults?.phoneNumber}`}
            linkText={searchResults?.phoneNumber}
            textDecoration="underline"
          />
        ) : null}
        {searchResults?.flyerCTA ? (
          <IconButton
            icon={GoTag}
            linkUrl={searchResults?.flyerCTA}
            linkText={formatMessage({ id: 'storeFlyer' })}
            textDecoration="underline"
          />
        ) : null}
      </div>
    ) : null;

  const renderStoreDetailsButtonSection = () => (
    <div className={isMapView ? styles.buttonContainerMapView : styles.cardButtonLinkContainer}>
      {searchResults?.bookAnAppointmentCTA && (
        <Button
          onClick={() => handleClick(searchResults?.bookAnAppointmentCTA, true)}
          size={isMapView ? 'small' : undefined}
        >
          {formatMessage({ id: 'bookAppointment' })}
        </Button>
      )}
      <Button
        onClick={() => handleClick(searchResults?.viewStoreDetailsCTA)}
        px={9}
        variant="secondary"
        size={isMapView ? 'small' : undefined}
      >
        {formatMessage({ id: 'viewStoreDetails' })}
      </Button>
    </div>
  );

  return (
    <>
      <div className={styles.searchResultsContainer} ref={forwardRef}>
        <PinsSvg pinNumber={svgPinNumber} {...(isPhx ? { height: 80 } : {})} />
        <div className={styles.cardInfoContainer}>
          <StoreInfoHeader searchResults={searchResults} />
          <StoreHoursSection
            searchResults={searchResults}
            isMapView={isMapView}
            postOfficeLink={searchResults?.canadaPostOfficeCTA}
          />
          {renderStoreAddressSection()}
          {renderIconButtonSection()}
          {renderStoreDetailsButtonSection()}
        </div>
      </div>
      {isDesktopView ? <Divider /> : null}
    </>
  );
};

export default SearchResultsContent;
