export enum E_ActionType {
  update = 'UPDATE_RESULTS',
  sort = 'SORT_RESULTS',
  loading = 'LOADING_RESULTS',
  error = 'ERROR',
}

export const PHARMACISTS_OWNERS_LINK = `store-locator/pharmacist-owners`;

/** status returned by Google Places API */
export const GOOGLE_PLACES_API_REQUEST_STATUS = {
  OK: 'OK',
  INVALID_REQUEST: 'INVALID_REQUEST',
  ZERO_RESULTS: 'ZERO_RESULTS',
  ERROR: 'ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  //used only for autocomplete service
  NOT_FOUND: 'NOT_FOUND',
};

export enum SearchAlertType {
  Empty = 'EMPTY_SEARCH',
  None = 'NONE',
  Invalid = 'INVALID_SEARCH',
  Error = 'ERROR',
  NoStores = 'NO_STORES',
  NonExistentStore = 'NON_EXISTENT',
}

export const enum TAB_STORE_LOCATOR {
  VIEW_LIST = 0,
  VIEW_MAP = 1,
}

const BASE_ICON_URL =
  'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/sdmi/2025/ad-hoc/mb-15512-store-details-page';
export const STORE_LOCATOR_TAB_ICONS = {
  VIEW_LIST_BLACK_URL: `${BASE_ICON_URL}/View%20list%20black.png`,
  VIEW_LIST_RED_URL: `${BASE_ICON_URL}/View%20list%20red.png`,
  VIEW_MAP_BLACK_URL: `${BASE_ICON_URL}/View%20map%20black.png`,
  VIEW_MAP_RED_URL: `${BASE_ICON_URL}/View%20map%20red.png`,
};
