import React, { FC } from 'react';
import { CustomAccordion, AccordionItemMenu } from '@ldfeplatform/componentlibrary.ui.molecules.accordion';

import { IAccordion } from './accordion.interface';
import styles from './accordion.module.scss';

const DEFAULT_OPEN_INDEX: number = 1;

const Accordion: FC<IAccordion> = ({ heading, body, defaultOpenIndex = [DEFAULT_OPEN_INDEX] }) => (
  <div className={styles.accordionContainer}>
    <CustomAccordion defaultIndex={defaultOpenIndex}>
      <AccordionItemMenu heading={heading}>{body}</AccordionItemMenu>
    </CustomAccordion>
  </div>
);

export default Accordion;
