import React from 'react';
import { useIntl } from 'react-intl';

import { Headline } from '@ldfeplatform/componentlibrary.ui.molecules.headline';

import { isPharmaprixBanner, capitalizeEveryFirstLetter } from '@/utils';
import { IHeaderGroupProps } from '../search-results-content.interface';
import { IStoreInfoHeader } from './store-info-header.interface';

const StoreInfoHeader: React.FC<IStoreInfoHeader> = ({ searchResults }) => {
  const { formatMessage } = useIntl();
  const isPhx = isPharmaprixBanner();

  const sdmheaderGroupProps: IHeaderGroupProps = {
    title: capitalizeEveryFirstLetter(searchResults?.name),
    subTitle: capitalizeEveryFirstLetter(searchResults?.bannerName),
    headlineSize: 4,
    paragraphSize: 2,
  };
  const phxHeaderGroupProps: IHeaderGroupProps = {
    title: capitalizeEveryFirstLetter(searchResults?.pharmacistOwner || formatMessage({ id: 'pharmacistOwner' })),
    subTitle: capitalizeEveryFirstLetter(searchResults?.name),
    headlineSize: 3,
    paragraphSize: 1,
  };

  const headerGroupProps = isPhx ? phxHeaderGroupProps : sdmheaderGroupProps;

  return <Headline {...headerGroupProps} gap={0} padding={0} />;
};

export default StoreInfoHeader;
