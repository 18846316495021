import dynamic from 'next/dynamic';
import React from 'react';

import { Spinner } from '@/components/shared';
import { INonSduiComponent } from './non-sdui.interface';
import SeoWrapper from '@/components/seo-wrapper/seo-wrapper';

const MastheadFooterContainer = dynamic(
  () => import('@/components/masthead-footer-container/masthead-footer-container').then((module) => module.default),
  {
    ssr: false,
    loading: () => <Spinner />,
  }
);

const NonSduiComponent: React.FC<INonSduiComponent> = ({
  children,
  seoMetaData,
  currentUrlBase,
  jsonLdScript,
  isPhxPharmacyTab,
}) => {
  return (
    <SeoWrapper seoMetaData={seoMetaData} currentUrlBase={currentUrlBase} jsonLdScript={jsonLdScript}>
      <MastheadFooterContainer isPhxPharmacyTab={isPhxPharmacyTab ?? false}>{children}</MastheadFooterContainer>
    </SeoWrapper>
  );
};

export default NonSduiComponent;
