import { FC, useRef } from 'react';
import { Card, CardContent } from '@ldfeplatform/componentlibrary.ui.atoms.card';

import { IMapCardProps } from './map-card.interface';

import styles from './map-card.module.scss';
import { scrollComponentRefIntoViewHorizontally } from '@/utils';
import SearchResultsContent from '../search-results-content/search-results-content.component';

export const MapCard: FC<IMapCardProps> = ({ index, position, onCardClick, searchResults, contentRef }) => {
  const cardRef = useRef<null | HTMLDivElement>(null);

  const handleCardClick = () => {
    if (position) {
      onCardClick({ lat: position?.latitude, lng: position?.longitude });
      scrollComponentRefIntoViewHorizontally(cardRef);
    }
  };

  return (
    <Card ref={cardRef} className={styles.locationCard} variant="short" onClick={handleCardClick}>
      <CardContent>
        <SearchResultsContent
          index={index}
          searchResults={searchResults}
          view="mapView"
          forwardRef={(el: HTMLDivElement) => {
            contentRef ? contentRef(index, el) : null;
          }}
        />
      </CardContent>
    </Card>
  );
};
