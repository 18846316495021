import React from 'react';

import { IPinsSvg } from './pins-svg.interface';
import styles from './pins-svg.module.scss';

const PinsSvg: React.FC<IPinsSvg> = ({ pinNumber = 0, height = 40, width = 40 }) => (
  <div className={styles.svgContainer}>
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* Outer circle or shape */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0388 39.1763C23.6287 39.1763 26.5388 37.6093 26.5388 35.6763C26.5388 33.7433 23.6287 32.1763 20.0388 32.1763C16.449 32.1763 13.5388 33.7433 13.5388 35.6763C13.5388 37.6093 16.449 39.1763 20.0388 39.1763Z"
        fill="#CACACA"
      />

      {/* Inner white shape */}
      <path
        d="M21.2083 29.8828C22.6331 28.4235 24.0579 26.8589 25.3855 25.2539C26.8006 23.543 28.0198 21.8872 28.9837 20.3289C30.4975 17.8815 31.3121 15.7807 31.3121 14.181C31.3121 7.90879 26.2467 2.82373 20.0018 2.82373C13.7538 2.82373 8.68787 7.90829 8.68787 14.181C8.68787 15.7807 9.50246 17.8815 11.0163 20.3289C11.9802 21.8872 13.1994 23.543 14.6145 25.2539C15.9421 26.8589 17.3669 28.4235 18.7917 29.8828C19.2241 30.3257 19.6295 30.731 20 31.0935C20.3705 30.731 20.7759 30.3257 21.2083 29.8828Z"
        fill="white"
      />

      {/* Outer red shape with inner details */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3121 14.181C33.3121 6.80595 27.353 0.82373 20.0018 0.82373C12.6476 0.82373 6.68787 6.80535 6.68787 14.181C6.68787 16.2329 7.62348 18.6457 9.31541 21.381C10.3323 23.025 11.6041 24.7522 13.0734 26.5286C14.4391 28.1797 15.8999 29.7839 17.3607 31.2801C17.8721 31.8038 18.3472 32.2769 18.7742 32.6916C19.0318 32.9419 19.2172 33.118 19.3183 33.2122L20 33.8474L20.6817 33.2122C20.7828 33.118 20.9682 32.9419 21.2258 32.6916C21.6528 32.2769 22.1279 31.8038 22.6393 31.2801C24.1001 29.7839 25.561 28.1797 26.9267 26.5286C28.3959 24.7522 29.6677 23.025 30.6846 21.381C32.3765 18.6457 33.3121 16.2329 33.3121 14.181ZM25.3855 25.2539C24.0579 26.8589 22.6331 28.4235 21.2083 29.8828C20.7759 30.3257 20.3705 30.731 20 31.0935C19.6295 30.731 19.2241 30.3257 18.7917 29.8828C17.3669 28.4235 15.9421 26.8589 14.6145 25.2539C13.1994 23.543 11.9802 21.8872 11.0163 20.3289C9.50246 17.8815 8.68787 15.7807 8.68787 14.181C8.68787 7.90829 13.7538 2.82373 20.0018 2.82373C26.2467 2.82373 31.3121 7.90879 31.3121 14.181C31.3121 15.7807 30.4975 17.8815 28.9837 20.3289C28.0198 21.8872 26.8006 23.543 25.3855 25.2539Z"
        fill="#CB333B"
      />

      {/* Text element to add the number "1" */}
      <text x="20" y="22" textAnchor="middle" fontSize="16" fill="#CB333B" fontFamily="Arial" fontWeight="bold">
        {pinNumber}
      </text>
    </svg>
  </div>
);

export default PinsSvg;
